import "./tease-feed.scss";

import { ChangeEvent, ReactNode, forwardRef } from "react";
import cn from "clsx";
import { Alignment, TeaseStyle } from "design-system/types/types";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import { TeaseFeedTease, LazyTeaseFeedTease } from "./tease-feed-tease";

interface TeaseFeedSortByOptionsProps {
  options: Array<TeaseFeedSortByOptions>;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  selectedOption?: string;
}

interface TeaseFeedSortByOptions {
  value: string;
  label: string;
}

export interface TeaseFeedProps {
  items: Array<LazyTeaseFeedTease>;
  headerContent?: ComponentHeaderProps;
  HeadingLevel?: "h2" | "h3";
  HeadingLevelTease?: "h2" | "h3" | "h4";
  align?: Alignment;
  teaseStyle?: TeaseStyle;
  searchInfo?: ReactNode;
  totalHits?: number;
  error?: string;
  sortByOptions?: TeaseFeedSortByOptionsProps;
}

/**
 * ## See it in use on...
 * - The [search page](/story/example-pages-archive-pages-search-archive--story)
 *
 * - **`id: CB-019-000-06`**
 * - **`data-region: cb__tease-feed__article`**
 */
export const TeaseFeed = forwardRef<HTMLDivElement, TeaseFeedProps>(
  function TeaseFeedWithRef(
    {
      items,
      headerContent,
      align = "center",
      teaseStyle = "expanded",
      totalHits,
      error,
      searchInfo,
      sortByOptions,
      HeadingLevel = "h2",
      HeadingLevelTease,
    },
    ref,
  ) {
    const wrapperClass = cn(
      `hbs-global-align-${align}`,
      "hbs-component--tease-feed",
    );
    const teaseTypes = [
      ...new Set(items.map((item) => item.props?.type).filter(Boolean)),
    ];
    const presentationalVariant =
      teaseTypes.length === 1 ? `__${teaseTypes[0]?.toLowerCase()}` : "";
    const dataRegion = `cb__tease-feed${presentationalVariant}`;

    return (
      <div ref={ref} className={wrapperClass} data-region={dataRegion}>
        <div className="hbs-tease-feed">
          {headerContent && (
            <ComponentHeader {...headerContent} HeadingLevel={HeadingLevel} />
          )}

          {(searchInfo || sortByOptions) && (
            <div className="hbs-tease-feed__header">
              {searchInfo && (
                <div className="hbs-tease-feed__search-info">{searchInfo}</div>
              )}

              {sortByOptions && (
                <div className="hbs-tease-feed__sort-info">
                  <p>Sorting By</p>
                  <div className="hbs-tease-feed__sort__select">
                    <select
                      id="sort-select"
                      onChange={sortByOptions.onChange}
                      value={sortByOptions.selectedOption}
                    >
                      {sortByOptions.options.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          )}

          {items.length > 0 ? (
            <ul className="hbs-tease-feed__list">
              {items.map((tease, i) => (
                <TeaseFeedTease
                  key={i}
                  tease={tease}
                  teaseStyle={teaseStyle}
                  headingLevel={HeadingLevelTease}
                />
              ))}
            </ul>
          ) : error ? (
            <div className="hbs-no-results">
              <p className="title">{error}</p>
            </div>
          ) : (
            totalHits === 0 && (
              <div className="hbs-no-results">
                <p className="title">No results found</p>

                <p>Suggestions:</p>
                <ul>
                  <li>Make sure all words are spelled correctly.</li>
                  <li>Try different keywords.</li>
                  <li>Try more general keywords.</li>
                  <li>Try fewer keywords.</li>
                </ul>
              </div>
            )
          )}
        </div>
      </div>
    );
  },
);
