import "./hierarchical-tease.scss";

import {
  ArticleTease,
  ArticleTeaseProps,
} from "design-system/components/primitives/article-tease/article-tease";
import { TeaseFeed } from "design-system/components/blocks/tease-feed/tease-feed";
import {
  HierarchicalTeaseHeader,
  HierarchicalTeaseHeaderProps,
} from "./hierarchical-tease-header";
import { EditAttributes, TeaseStyle } from "design-system/types/types";
import { MouseEventHandler } from "react";

export interface HierarchicalTeaseProps {
  header?: HierarchicalTeaseHeaderProps;
  articles: Array<ArticleTeaseProps>;
  feedTeaseStyle?: TeaseStyle;
  onClick?: MouseEventHandler;
  editAttributes?: {
    query?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - The [homepage](/story/example-pages-homepage--homepage)
 *
 * - **`id: CB-008-000-00`**
 * - **`data-region: cb__hierarchical-tease`**
 */
export function HierarchicalTease({
  header,
  articles,
  feedTeaseStyle,
  editAttributes,
}: HierarchicalTeaseProps) {
  const featuredArticle = articles[0];
  const otherArticles = articles.slice(
    1,
    articles.length > 4 ? 3 : articles.length,
  );

  if (featuredArticle?.image) {
    featuredArticle.image.aspectRatio = "3/2";
  }

  if (featuredArticle?.video) {
    featuredArticle.video.aspectRatio = "3/2";
  }

  if (featuredArticle && header) {
    featuredArticle.HeadingLevel = "h3";
  }

  return (
    <div
      className="hbs-global-align-full hbs-component--hierarchical-tease"
      data-region="cb__hierarchical-tease"
    >
      <div className="hbs-hierarchical-tease">
        {header && <HierarchicalTeaseHeader {...header} />}

        <div
          {...editAttributes?.query}
          className="hbs-hierarchical-tease__articles"
        >
          <div className="hbs-hierarchical-tease__big-preview">
            {featuredArticle && <ArticleTease {...featuredArticle} />}
          </div>
          <div className="hbs-hierarchical-tease__small-preview">
            <TeaseFeed
              HeadingLevelTease={header ? "h3" : "h2"}
              items={otherArticles.map((props) => ({
                name: "ArticleTease",
                props,
              }))}
              teaseStyle={feedTeaseStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
